var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo-mercadohit.svg"),"width":"150"}})])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Primeiro Acesso 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Defina sua senha para começar a usar a plataforma. ")]),_c('validation-observer',{ref:"primeiroAcessoForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.definirNovaSenha($event)}}},[_c('b-form-group',{attrs:{"label":"Senha Atual","label-for":"senhaAntiga"}},[_c('validation-provider',{attrs:{"name":"senhaAntiga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"senhaAntiga","state":errors.length > 0 ? false : null,"name":"senhaAntiga","placeholder":"Digite sua senha atual","type":"password"},model:{value:(_vm.senhaAntiga),callback:function ($$v) {_vm.senhaAntiga=$$v},expression:"senhaAntiga"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Nova Senha","label-for":"senhaNova"}},[_c('validation-provider',{attrs:{"name":"senhaNova","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"senhaNova","state":errors.length > 0 ? false : null,"name":"senhaNova","placeholder":"Digite sua nova senha","type":"password"},model:{value:(_vm.senhaNova),callback:function ($$v) {_vm.senhaNova=$$v},expression:"senhaNova"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirmar Nova Senha","label-for":"confirmarSenhaNova"}},[_c('validation-provider',{attrs:{"name":"confirmarSenhaNova","rules":"required|confirmed:senhaNova"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmarSenhaNova","state":errors.length > 0 ? false : null,"name":"confirmarSenhaNova","placeholder":"Confirme sua nova senha","type":"password"},model:{value:(_vm.confirmarSenhaNova),callback:function ($$v) {_vm.confirmarSenhaNova=$$v},expression:"confirmarSenhaNova"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.respostaApi}},[_vm._v(" Definir Nova Senha "),(_vm.respostaApi)?_c('b-spinner',{attrs:{"small":"","label":"Carregando"}}):_vm._e()],1)],1)]}}])})],1)],1),_c('b-modal',{ref:"confirmacaoCarregando",attrs:{"id":"confirmacaoCarregando","centered":"","title":"Recuperação de Senha","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header-close":""}},[_c('b-card-text',{staticClass:"text-center p-2"},[_c('b-spinner',{staticClass:"mt-2",attrs:{"variant":"primary"}}),_c('br'),_c('strong',[_vm._v("Aguarde um instante!")]),_c('br'),_vm._v(" Redefinindo senha! ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }