<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            <img
              src="@/assets/images/logo/logo-mercadohit.svg"
              width="150"
            >
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Primeiro Acesso 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Defina sua senha para começar a usar a plataforma.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="primeiroAcessoForm" v-slot="{ invalid }">

          <b-form class="auth-login-form mt-2" @submit.prevent="definirNovaSenha">
            <b-form-group label="Senha Atual" label-for="senhaAntiga">
              <validation-provider v-slot="{ errors }" name="senhaAntiga" rules="required">
                <b-form-input id="senhaAntiga" v-model="senhaAntiga" :state="errors.length > 0 ? false : null"
                  name="senhaAntiga" placeholder="Digite sua senha atual" type="password" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Nova Senha" label-for="senhaNova">
              <validation-provider v-slot="{ errors }" name="senhaNova" rules="required|min:8">
                <b-form-input id="senhaNova" v-model="senhaNova" :state="errors.length > 0 ? false : null"
                  name="senhaNova" placeholder="Digite sua nova senha" type="password" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Confirmar Nova Senha" label-for="confirmarSenhaNova">
              <validation-provider v-slot="{ errors }" name="confirmarSenhaNova" rules="required|confirmed:senhaNova">
                <b-form-input id="confirmarSenhaNova" v-model="confirmarSenhaNova" :state="errors.length > 0 ? false : null"
                  name="confirmarSenhaNova" placeholder="Confirme sua nova senha" type="password" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button type="submit" variant="primary" block :disabled="invalid || respostaApi">
              Definir Nova Senha
              <b-spinner v-if="respostaApi" small label="Carregando"/>
            </b-button>
         </b-form>

        </validation-observer>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>

    <!-- basic modal -->
    <b-modal
      id="confirmacaoCarregando"
      ref="confirmacaoCarregando"
      centered
      title="Recuperação de Senha"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-text class="text-center p-2">
        <b-spinner
          class="mt-2"
          variant="primary"
        /><br>
        <strong>Aguarde um instante!</strong><br> Redefinindo senha!
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'   
import { required, min, confirmed } from 'vee-validate/dist/rules'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { email } from '@validations'
import { localize } from "vee-validate";

import useJwt from '@/auth/jwt/useJwt'

localize({
    en: {
      messages: {
        required: 'Este campo é obrigatório',
        min: 'A senha deve ter no mínimo {length} caracteres',
      },
      fields: {
        senhaAtual: {
          required: 'A senha é obrigatória.',
        },
        senhaNova: {
          required: 'A senha é obrigatória.',
        },
        confirmarSenhaNova: {
          required: 'A confirmação da nova senha é obrigatória',
          confirmed: 'As senhas não são idênticas.'
        },
      }
    }
  });


export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      usuarioId: null,
      senhaAntiga: '',
      senhaNova: '',
      confirmarNovaSenha: '',
      respostaApi: false
    }
  },
  mounted() {
    const usuarioData = JSON.parse(localStorage.getItem('usuarioData'))
    if (usuarioData) {
      this.usuarioId = usuarioData.sub
    }
  },
  methods: {
    definirNovaSenha() {
      this.respostaApi = true;
      
      useJwt.usuarioTrocarSenha({
        usuarioId: this.usuarioId,
        senhaAntiga: this.senhaAntiga,
        novaSenha: this.senhaNova
      }).then(response => {
        
        this.$swal({
        title: 'Senha alterada!',
        text: 'A senha foi alterada .',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {

          this.$router.push('/')

        } else {

          this.$router.push('/')

        }
      })
      })
      .catch(error => {
        console.log( error );
        this.respostaApi = false;
                this.$swal({
                  title: 'Erro!',
                  text: error.response.data,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
          })
        .finally(() => {
          this.respostaApi = false;
        });
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>

